<template lang="">
    <div>
        <PurposeDetails/>
    </div>
</template>
<script>
export default {
    components: {
    PurposeDetails: () => import('@/components/manage/purpose/PurposeDetails.vue'),
}
    
}
</script>
<style lang="">
    
</style>